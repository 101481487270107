/* Main Container */
.care-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 70px;
  background-color: #fff;
  color: #000;
  font-family: "Arial", sans-serif;
}

/* Heading */
.care-title {
  padding-top: 9%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #333;
  margin-bottom: 40px;
}

/* Slider Container */
.care-slider {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
}

.responsive-image {
  width: 100%;            /* Adjust width to fit the container */
  max-width: 500px;       /* Limit maximum width */
  height: auto;           /* Maintain aspect ratio */
  max-height: 300px;      /* Set a maximum height */
  object-fit: contain;    /* Ensure the image scales properly without cropping */
  border-radius: 8px;     /* Rounded corners */
  margin: 0 auto;         /* Center the image */
  display: block;         /* Avoid any inline spacing issues */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .responsive-image {
    max-width: 400px;   /* Smaller max width for tablets */
    max-height: 250px;  /* Smaller height for better scaling */
  }
}

@media (max-width: 480px) {
  .responsive-image {
    max-width: 300px;   /* Smaller width for mobile devices */
    max-height: 200px;  /* Smaller height for mobile devices */
  }
}

/* Content Sections */
.care-details {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
}

.care-overview,
.care-principles {
  margin-bottom: 30px;
}

.care-overview h3,
.care-principles h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.care-overview p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.care-principles ul {
  padding-left: 20px;
  list-style-type: disc;
  line-height: 1.8;
}

/* Buttons */
.care-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.care-button {
  display: inline-block;
  padding: 12px 24px; /* Consistent padding */
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  min-width: 180px; /* Ensures buttons have the same width */
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.3s ease;
}

.button-outline {
  border: 2px solid black;
  color: black;
}

.button-outline:hover {
  background-color: black;
  color: white;
}

.button-solid {
  background-color: black;
  color: white;
}

.button-solid:hover {
  background-color: white;
  color: black;
  border: 2px solid black;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .care-title {
    font-size: 1.8rem;
  }

  .care-slider {
    max-width: 80%;
  }

  .care-overview h3,
  .care-principles h3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .care-title {
    font-size: 1.6rem;
  }

  .care-slider {
    max-width: 100%;
  }

  .care-overview h3,
  .care-principles h3 {
    font-size: 1.5rem;
  }

  .care-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .care-button {
    min-width: 100%; /* Full-width buttons on smaller screens */
  }
}

@media (max-width: 480px) {
  .care-title {
    font-size: 1.4rem;
  }

  .care-slider {
    max-width: 100%;
  }

  .responsive-image {
    max-width: 100%;
  }

  .care-overview p {
    font-size: 1rem;
  }

  .care-buttons {
    gap: 10px;
  }

  .care-button {
    min-width: 100%; /* Full-width buttons on mobile */
    font-size: 0.9rem;
  }
}
/* Locomotive Scroll Container */
[data-scroll-container] {
  overflow: hidden; /* Ensures smooth scrolling behavior */
}

/* Smooth Scrolling Content */
[data-scroll] {
  will-change: transform, opacity;
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}
