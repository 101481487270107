/* Footer Styles */
.footer {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  padding: 40px 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-section {
  margin-bottom: 20px;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between icons */
  margin-top: 10px;
}

.social-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  transition: transform 0.3s ease;
}

.social-image {
  width: 100%;
  height: 100%;
  object-fit: cover;/* Make icons circular */
}

.social-icon:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  padding-top: 20px;
  font-size: 14px;
  color: #ccc;
}
