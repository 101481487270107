/* General Styling */

/* Portfolio Section Styling */
.portfolio {
  height: auto;
  padding: 40px;
  font-family: "Arial", sans-serif;
  background-color: white;
}

/* Portfolio Header */
.portfolio-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 20px; /* Align with logo */
}

.portfolio-title {
  font-size: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 16px;
}

.portfolio-line {
  flex-grow: 1;
  height: 2px;
  background: black;
}

/* Tabs Styling */
.tabs {
  display: flex;
  justify-content: flex-start; /* Align tabs to the left */
  gap: 40px; /* Increased gap for better spacing */
  margin-bottom: 20px;
  padding-left: 20px; /* Align with header */
}

.tab-button {
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #555;
  border-bottom: 2px solid transparent;
  padding-bottom: 8px;
  transition: all 0.3s;
}

.tab-button.active {
  color: black;
  font-weight: bold;
  border-bottom: 2px solid black;
}

.tab-button:hover {
  color: black;
}

/* Projects Grid */
.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Responsive grid */
  gap: 20px; /* Gap between items */
  margin-top: 20px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Fade Animations */
/* Faster fade-out and fade-in animations */
.fade-out {
  animation: fadeOut 0.2s ease-out; /* Reduced duration from 0.3s to 0.2s */
}

.fade-in {
  animation: fadeIn 0.2s ease-in; /* Reduced duration from 0.3s to 0.2s */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.projects {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
/* Update icon styles */
.project-link{
  font-size: 1.2rem; /* Adjust the icon size */
  color: #333333; /* Customize color */
  stroke-width: 0.5px; /* Set the stroke width */
  stroke: currentColor; /* Apply stroke color same as the text color */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.project-link:hover {
  transform: scale(1.1); /* Slight scaling on hover */
}

/* Project Card Styling */
.project-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  outline: 1.5px solid #0a0303;
  border-radius: 8px;
  padding: 16px; /* Uniform padding */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure uniform height */
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.project-image {
  width: 100%;
  height: 180px; /* Set consistent height */
  object-fit: contain; /* Preserve aspect ratio */
}

.project-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0; /* Uniform padding */
  font-size: 14px;
}

.project-link {
  text-decoration: none;
  font-size: 18px;
  color: black;
  transition: color 0.3s;
}

.project-link:hover {
  color: #555;
}

/* Horizontal Line Styling */
.hline {
  width: calc(100% - 20px); /* Padding inside cards */
  height: 1px; /* Thickness of the line */
  background-color: black; /* Line color */
  margin: 10px auto; /* Center it horizontally */
}

/* Responsive Styles */

/* Large Screens */
@media (min-width: 1200px) {
  .projects {
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
    gap: 30px;
  }

  .project-card {
    max-width: 280px; /* Limit max width */
    margin: 0 auto; /* Center the cards */
  }

  .project-image {
    padding: 12px;
  }
}

/* Medium Screens - Tablets */
@media (max-width: 768px) {
  .projects {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    gap: 20px;
  }

  .tabs {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px; /* Compact spacing */
    padding-left: 10px;
  }

  .project-card {
    padding: 12px; /* Adjust padding */
  }

  .hline {
    width: calc(100% - 0px); /* Adjust for smaller cards */
  }

  .project-image {
    height: 150px; /* Adjusted image size */
  }
}

/* Small Screens - Mobile */
@media (max-width: 480px) {
  .projects {
    grid-template-columns: 1fr; /* Force 1 item per row */
    gap: 15px; /* Reduce gap */
  }

  .tabs {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px; /* Compact spacing for smaller devices */
  }

  .project-card {
    padding: 12px; /* Adjust padding */
  }

  .project-image {
    height: 150px; /* Smaller height for mobile */
  }

  .hline {
    width: 100%; /* Full width for mobile */
  }
}

/* Extra Small Screens */
@media (max-width: 344px) {
  .projects {
    grid-template-columns: 1fr; /* Ensure 1 item per row */
    gap: 10px; /* Compact spacing */
  }

  .tabs {
    flex-direction: row;
    gap: 10px; /* Smaller gap */
  }

  .project-card {
    padding: 10px; /* Smaller padding */
  }

  .project-image {
    height: 130px; /* Adjusted for very small screens */
  }

  .hline {
    width: 100%; /* Full width */
  }
}

/* Footer Styling */
.footer {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #666;
}

/* Social Media Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 10px;
}

.social-icons a {
  font-size: 20px;
  color: black;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #555;
}
