/* Root Variables */
:root {
  --primary-color: #000;
  --secondary-color: rgba(0, 0, 0, 0.4);
  --background-color: #fff;
  --font-primary: "Inclusive Sans", Helvetica, sans-serif;
  --line-height: 2px;
  --logo-size: 1.5rem;
  --ellipse-size: 30px;
}

/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-primary);
  background-color: var(--background-color);
  color: var(--primary-color);
  padding-top: 70px;
}
/* General Header Styles */
.header {
  position: fixed; /* Fixes the header at the top */
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* White background */
  z-index: 1000; /* Keeps header above all content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
/* Header Container */
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 4%;
  background-color: var(--background-color);
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Logo and Ellipse */
.logo {
  font-family: var(--font-primary);
  font-size: var(--logo-size);
  letter-spacing: 1.4px;
  color: var(--primary-color);
}

.ellipse {
  width: var(--ellipse-size);
  height: var(--ellipse-size);
  background-color: var(--primary-color);
  border-radius: 50%;
}

/* Line */
.line {
  position: absolute;
  bottom: 0;
  left: 0;
  height: var(--line-height);
  background-color: var(--primary-color);
  width: 0; /* Start at 0 width */
}
/* Animation Class for the Line */
.line.animate-line {
  animation: lineGrow 2s ease-out forwards;
}

/* Line Animation */
@keyframes lineGrow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  :root {
    --logo-size: 1.25rem;
    --ellipse-size: 24px;
  }

  .header-container {
    padding: 12px 3%;
  }

  .line {
    height: 1.5px;
  }
}

@media (max-width: 768px) {
  :root {
    --logo-size: 1rem;
    --ellipse-size: 20px;
  }

  .header-container {
    padding: 12px 3%;
  }

  .line {
    height: 1.2px;
  }
}

@media (max-width: 480px) {
  :root {
    --logo-size: 0.9rem;
    --ellipse-size: 18px;
  }

  .header-container {
    padding: 8px 2%;
  }

  .line {
    height: 1px;
  }
}
/* Back Arrow */
.back-arrow {
  font-size: 1.8rem; /* Same size as the ellipse */
  font-weight: bold;
  cursor: pointer;
}

.back-arrow:hover {
  color: #010202; /* Optional: Highlight color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Ellipse (Home Page Only) */
.ellipse {
  width: 30px;
  height: 30px;
  background-color: #333;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .back-arrow {
    font-size: 1.5rem; /* Reduce size for smaller screens */
  }
}

@media (max-width: 480px) {
  .back-arrow {
    font-size: 1.3rem; /* Further reduce size for very small screens */
  }
}

