/* General Styling */


/* Hero Section */
.hero {
  position: relative;
  height: 100vh; /* Full-screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.11) 70.09%,
    rgba(0, 0, 0, 0.2) 87.5%
  );
  background-color: rgba(255, 255, 255, 1.0); 
}

/* Hero Container */
.hero-container {
  display: flex;
  justify-content: space-between; /* Space between left and right sections */
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  animation: fadeInUp 1s ease-in-out; /* Hero container animation */
}

/* Hero Left */
.hero-left {
  flex: 1;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  animation: slideInLeft 1s ease-in-out;
  padding-left: 20px; /* Align text properly with buttons */
}

.hero-right {
  flex: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh; /* Default height */
  width: 100vh; /* Default width */
  animation: slideInRight 1s ease-in-out;
  padding: 30px; /* Padding similar to provided code */
  position: relative; /* Ensure relative positioning */
  overflow: hidden; /* Prevent content overflow */
  pointer-events: auto; /* Allow interactions */
  touch-action: none; /* Disable touch gestures */
}

/* Responsive Design for Mobile Devices */
@media (max-width: 820px) {
  .hero-right {
    height: 40vh; /* Maintain 40vh height */
    width: 100vh; /* Full width for mobile */
  }
}

@media (max-width: 540px) {
  .hero-right {
    height: 40vh;
    width: 100vh;
    padding: 20px; /* Reduce padding for smaller devices */
  }
}

@media (max-width: 480px) {
  .hero-right {
    height: 40vh;
    width: 100vh;
    padding: 15px; /* Further reduce padding */
  }
}

@media (max-width: 344px) {
  .hero-right {
    height: 40vh;
    width: 100vh;
    padding: 10px; /* Compact padding */
  }
}

/* Canvas Styling */
.hero-right canvas {
  display: block;
  width: 855px; /* Fixed width for canvas */
  height: 306px; /* Fixed height for canvas */
}

.hero-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  animation: float 3s ease-in-out infinite; /* Floating animation */
}

/* Typography */
.greeting {
  font-size: 1.25rem;
  color: #8d8d8d;
  margin-bottom: 16px;
  animation: fadeIn 1.5s ease-in-out;
}

.wave {
  font-size: 1.5rem;
  display: inline-block;
  animation: waveHand 1.5s ease-in-out infinite;
  transform-origin: 70% 70%;
}

.name {
  font-weight: bold;
}

.tagline {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  animation: fadeInUp 1.5s ease-in-out;
}

.word-create {
  display: inline-block;
  font-weight: bold;
  animation: scaleUp 2s infinite ease-in-out;
}

.subtext {
  font-size: 1rem;
  color: #8d8d8d;
  margin-bottom: 32px;
  animation: fadeIn 1.5s ease-in-out;
}

/* Buttons */
.buttons {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap; /* Ensure buttons stay in one row */
  justify-content: flex-start; /* Align buttons to the left */
}

.button {
  font-size: 1rem;
  padding: 12px 24px;
  border: 2px solid #000;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: fadeInUp 1s ease-in-out;
}

.recent-projects {
  background-color: transparent;
  color: #000;
}

.view-resume {
  background-color: #000;
  color: #fff;
}

.button:hover {
  transform: scale(1.05);
}

/* Spider Web Overlay */
.spider-web-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url("../assets/spider_web.png") no-repeat center;
  background-size: cover; /* Fit to the screen size */
  opacity: 0.3;
  z-index: 0; /* Behind content */
}

.hero-container {
  position: relative;
  z-index: 1;
}

/* Responsive Design */
@media (max-width: 820px) {
  .hero-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hero-left {
    flex: 1;
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }

  

  .buttons {
    flex-direction: row;
    gap: 12px;
  }
}

@media (max-width: 540px) {
  .tagline {
    font-size: 2rem;
  }

  .buttons {
    flex-direction: row;
    gap: 10px;
  }

  
}

@media (max-width: 480px) {
  .tagline {
    font-size: 1.8rem;
  }

  .buttons {
    flex-direction: row;
    gap: 8px;
  }

 
}

@media (max-width: 344px) {
  .tagline {
    font-size: 1.5rem;
  }

  .buttons {
    flex-direction: row;
    gap: 6px;
  }

  .hero-right {
    height: 40vh; /* Compact height for smallest screens */
  }
}
