/* Import Fonts and Reset */
@import url("https://fonts.googleapis.com/css2?family=Inclusive+Sans:wght@400&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

/* General Styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

html, body {
  height: 100%;
  font-family: "Inclusive Sans", Arial, sans-serif;
  scroll-behavior: smooth;
}

/* Preloader Styling */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000; /* Black background */
  color: #fff; /* White text */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 9999; /* Ensure it's above everything */
}

/* Letters Styling */
.preloader-text {
  font-size: 3rem; /* Adjust font size for desktop */
  letter-spacing: 4px;
  display: flex;
  gap: 8px; /* Spacing between letters */
  text-align: center; /* Center-align text */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .preloader-text {
    font-size: 2.5rem; /* Adjust font size for smaller screens */
    gap: 6px; /* Reduce spacing between letters */
  }
}

@media (max-width: 480px) {
  .preloader-text {
    font-size: 2rem; /* Smaller font size for mobile */
    gap: 4px; /* Further reduce spacing */
  }
}

/* Animations for Letters */
.letter {
  opacity: 0; /* Start invisible */
  animation: fadeInLetter 0.5s ease-in-out forwards;
}

.letter:nth-child(1) {
  animation-delay: 0s;
}
.letter:nth-child(2) {
  animation-delay: 0.2s;
}
.letter:nth-child(3) {
  animation-delay: 0.4s;
}
.letter:nth-child(4) {
  animation-delay: 0.6s;
}
.letter:nth-child(5) {
  animation-delay: 0.8s;
}
.letter:nth-child(6) {
  animation-delay: 1s;
}
.letter:nth-child(7) {
  animation-delay: 1.2s;
}

/* Fade-in Animation */
@keyframes fadeInLetter {
  0% {
    opacity: 0;
    transform: translateY(-10px); /* Slight rise effect */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Original position */
  }
}

/* Preloader Fade-Out */
#preloader.hidden {
  animation: fadeOut 0.5s ease-out forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
