/* Main Container */
.empowered-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 70px;
    background-color: #fff;
    color: #000;
    font-family: "Arial", sans-serif;
  }
  
  /* Heading */
  .empowered-title {
    padding-top: 9%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: lighter;
    color: #333;
    margin-bottom: 40px;
  }
  
  /* Slider Container */
  .empowered-slider {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
  }
  
  /* Content Sections */
  .empowered-details {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
  }
  
  .empowered-overview,
  .empowered-principles {
    margin-bottom: 30px;
  }
  
  .empowered-overview h3,
  .empowered-principles h3 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .empowered-overview p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .empowered-principles ul {
    padding-left: 20px;
    list-style-type: disc;
    line-height: 1.8;
  }
  
  /* Buttons */
  .empowered-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .empowered-button {
    display: inline-block;
    padding: 12px 24px; /* Consistent padding */
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    min-width: 180px; /* Ensures buttons have the same width */
    font-size: 1rem;
    line-height: 1.5;
    transition: all 0.3s ease;
  }
  
  .button-outline {
    border: 2px solid black;
    color: black;
  }
  
  .button-outline:hover {
    background-color: black;
    color: white;
  }
  
  .button-solid {
    background-color: black;
    color: white;
  }
  
  .button-solid:hover {
    background-color: white;
    color: black;
    border: 2px solid black;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .empowered-title {
      font-size: 1.8rem;
    }
  
    .empowered-slider {
      max-width: 80%;
    }
  
    .empowered-overview h3,
    .empowered-principles h3 {
      font-size: 1.6rem;
    }
  }
  
  @media (max-width: 768px) {
    .empowered-title {
      font-size: 1.6rem;
    }
  
    .empowered-slider {
      max-width: 100%;
    }
  
    .empowered-overview h3,
    .empowered-principles h3 {
      font-size: 1.5rem;
    }
  
    .empowered-buttons {
      flex-direction: column;
      gap: 15px;
    }
  
    .empowered-button {
      min-width: 100%; /* Full-width buttons on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .empowered-title {
      font-size: 1.4rem;
    }
  
    .empowered-slider {
      max-width: 100%;
    }
  
    .responsive-image {
      max-width: 100%;
    }
  
    .empowered-overview p {
      font-size: 1rem;
    }
  
    .empowered-buttons {
      gap: 10px;
    }
  
    .empowered-button {
      min-width: 100%; /* Full-width buttons on mobile */
      font-size: 0.9rem;
    }
  }
  
  /* Locomotive Scroll Container */
  
  /* Section Spacing */
.section-gap {
  margin: 5px 0;
}

/* Bullet Lists */
.bullet-list {
  padding-left: 20px;
  list-style-type: disc;
  line-height: 1.8;
}

/* Section Heading */
.section-heading {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: bold;
}

/* Design Principles */
.empowered-principles ul {
  padding-left: 20px;
  list-style-type: disc;
  line-height: 1.8;
}

/* Buttons */
.empowered-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Responsive Buttons */
.empowered-button {
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: bold;
  min-width: 180px;
  text-align: center;
}
